import React, { useEffect, useState } from 'react';
import { extensionListToAcceptAttribute, onChangeFileInput } from '../../utils/Methods';
import { uploadInjectionResource, uploadQrCode } from '../../api/apiCalls';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import {
  FAILED_ERROR_MESSAGE,
  FILE_NAME_HEADER,
  hardwareSimulateMessages,
  IMAGE_NAME,
  libraryMessages,
  UPLOAD_BUTTON
} from '../../utils/UIMessages';
import axios from 'axios';
import {
  Button,
  Checkbox,
  Form,
  FormField,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  ModalDescription
} from 'semantic-ui-react';
import { UPLOAD_ICON } from '../../utils/UiIcons';
import FileSelectionForm from '../../components/FileSelectionForm';
import { BarcodeFormat, BrowserMultiFormatReader } from "@zxing/browser";

const SimulationResourceUpload = (props) => {
  const [resFile, setResFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [fileExtensionErrorMsg, setFileExtensionErrorMessage] = useState('');
  const extensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const [uploadBtnDisable, setUploadBtnDisable] = useState(true);
  const [cameraxChecked, setCameraxChecked] = useState(false);
  const [qrCodeChecked, setQrCodeChecked] = useState(false);
  const [qrDetected, setQrDetected] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [qrCodeResult, setQrCodeResult] = useState(null);

  const acceptableExtensions = extensionListToAcceptAttribute(extensions);

  const onChangeFileUpload = async (e) => {
    clearStates();
    const file = e.target.files[0];
    onChangeFileInput(
      file,
      extensions,
      (errorMsg) => setFileExtensionErrorMessage(errorMsg),
      (resFile) => setResFile(resFile),
      (fileName) => setFileName(fileName)
    );
    await readFile(file);
  };

  const clearStates = () => {
    setFileName('');
    setResFile(null);
    setLoadingFileUpload(false);
    setFileExtensionErrorMessage('');
    setQrCodeResult(null);
    setImageSrc(null);
    setQrCodeChecked(false);
    setQrDetected(false);
    setCameraxChecked(false);
    setQrCodeChecked(false);
  };

  const closeModal = () => {
    props.handleCloseModal();
    clearStates();
  };

  const readFile = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result;
        setImageSrc(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadResource = async () => {
    setLoadingFileUpload(true);
    const formData = new FormData();
    formData.append('file', resFile.get('file'));

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      params: {
        udId: props.deviceUdId,
        resourceType: 'IMAGE'
      }
    };

    try {
      await uploadInjectionResource(formData, config);
      showSuccess(hardwareSimulateMessages().RES_SUCCESS_UPLOAD, 1000);
    } catch (err) {
      if (axios.isCancel(err)) {
        showError(hardwareSimulateMessages().RES_UNSUCCESSFUL_UPLOAD);
      } else {
        showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`);
      }
    }
  };

  const uploadQR = async () => {
    try {
      const body = generateQrBody();
      await uploadQrCode({ udId: props.deviceUdId }, body);
      showSuccess(libraryMessages().SHOW_SUCCESS_FILE_UPLOAD, 1000);
    } catch (err) {
      if (axios.isCancel(err)) {
        showError(hardwareSimulateMessages().RES_UNSUCCESSFUL_UPLOAD);
      } else {
        showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`);
      }
    }
  };

  const handleUpload = async () => {
    if (cameraxChecked) {
      await uploadResource();
    }
    if (qrCodeChecked) {
      await uploadQR();
    }
    closeModal();
  };

  useEffect(() => {
    if ((cameraxChecked || qrCodeChecked) && resFile) {
      setUploadBtnDisable(false);
    } else {
      setUploadBtnDisable(true);
    }
  }, [cameraxChecked, qrCodeChecked, resFile]);

  const handleCameraxCheckboxChange = (e, { checked }) => {
    setCameraxChecked(checked);
  };

  const handleQrCodeChange = (e, { checked }) => {
    setQrCodeChecked(checked);
  };

  useEffect(async () => {
    if (imageSrc) {
      const element = document.createElement('img');
      element.src = imageSrc;
      try {
        const reader = new BrowserMultiFormatReader();
        let result = await reader.decodeFromImageElement(element);
        if (result) {
          setQrCodeResult(result);
        }
      } catch (e) {
        console.error('Error when qr reader: ', e);
      }
    }
  }, [imageSrc]);

  useEffect(() => {
    if (qrCodeResult) {
      setQrDetected(true);
      setQrCodeChecked(true);
    }
  }, [qrCodeResult]);

  const generateQrBody = () => {
    if (qrCodeResult) {
      const format = BarcodeFormat[qrCodeResult.format];
      const resultBytes = qrCodeResult.rawBytes;
      let rawBytes = null;
      if(resultBytes) {
        rawBytes = window.btoa(String.fromCharCode(...qrCodeResult.rawBytes));
      }
      return { ...qrCodeResult, format: format, rawBytes: rawBytes, numBits: undefined , resultMetadata: undefined};
    }
  };

  return (
    <>
      <Modal
        open={props.visibility}
        onClose={props.handleCloseModal}
        size="medium"
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        centered={false}>
        <Header icon={UPLOAD_ICON} content={hardwareSimulateMessages().RES_HEADER} />
        <Modal.Content image className="simulation-res-upload-modal">
          <div id="simulation-img-container">
            {imageSrc ? (
              <div>
                <Image verticalAlign={'middle'} size="medium" src={imageSrc} />
                {qrCodeResult && <p className="text-align-center">{'QR Code: ' + qrCodeResult.text}</p>}
              </div>
            ) : (
              <div id="simulation-res-upload-inf-grid">
                <div id="simulation-res-upload-inf-label-container">
                  <Label className="" content="Please upload a image!" icon={UPLOAD_ICON} />
                </div>
              </div>
            )}
          </div>

          <ModalDescription className="simulation-res-upload-modal-desc">
            <div>
              <div>
                <p>{hardwareSimulateMessages().RES_DESCRIPTION}</p>
              </div>
              <div className="ui grid">
                {props.isContainFileName && (
                  <Form>
                    <Form.Field required>
                      <label style={{ textAlign: 'left' }}>{FILE_NAME_HEADER()}</label>
                      <Input value={fileName} onChange={(e, { value }) => props.onChangeFileName(value)} />
                    </Form.Field>
                  </Form>
                )}
              </div>
              <FileSelectionForm
                headerName={IMAGE_NAME()}
                acceptableExtensions={acceptableExtensions}
                errMsg={fileExtensionErrorMsg}
                onChangeFileUpload={onChangeFileUpload}
              />
              <Form className="simulation-res-upload-form">
                <label>Select hardware simulate session type</label>
                <FormField className="simulation-res-upload-form-field">
                  <Checkbox
                    label="CameraX Take Picture"
                    checked={cameraxChecked}
                    onChange={handleCameraxCheckboxChange}
                  />
                </FormField>
                <FormField className="simulation-res-upload-form-field">
                  <Checkbox
                    label="Zxing QR Code"
                    checked={qrCodeChecked}
                    onChange={handleQrCodeChange}
                    disabled={!qrDetected}
                  />
                  {qrDetected && <Label pointing="left">QR Code Detected</Label>}
                </FormField>
              </Form>
              <div style={{ textAlign: 'center', marginTop: 25 }}>
                <Button
                  loading={loadingFileUpload}
                  content={UPLOAD_BUTTON()}
                  icon={<Icon name={UPLOAD_ICON} />}
                  color="green"
                  onClick={handleUpload}
                  disabled={uploadBtnDisable}
                />
              </div>
            </div>
          </ModalDescription>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default SimulationResourceUpload;
